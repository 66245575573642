import React, { useState, useEffect } from "react"
import signUpBgImg from "../../images/sign-up/sign-up-bg.png"
import logoImg from "../../images/sign-up/logo.svg"
import checkImg from "../../images/new-pricing/checked.svg"
import Axios from "axios"
import {generateNSIPPromoCode, getAllUrlParams} from "../../utils"
import {
  SOLO_PLAN,
  STARTER_PLAN,
  STANDARD_PLAN,
  BLB_PARTNER,
  GET_PAID_PLAN,
  PAY_BILLS_PLAN, COMBINED_PLAN,
} from "../../data/pricing-features"
import queryString from "query-string";
import { navigate } from "gatsby"
import classNames from "classnames";
import lucapayLogoImage from "../../images/new-pricing/lucapay-logo.svg";
import loadingImg from "../../images/loading-gear-white.svg";
import signupUserImg from "../../images/sign-up/step1.png";
import signupClientImg from "../../images/sign-up/step2.png";
import signupBusinessImg from "../../images/sign-up/step3.png";
import ssignupUserImg from "../../images/sign-up/sstep1.png";
import ssignupBusinessImg from "../../images/sign-up/sstep2.png";
import ssignupPracticeImg from "../../images/sign-up/sstep3.png";
import ssignupClientImg from "../../images/sign-up/sstep4.png";
import blbPartnerImg from "../../images/sign-up/blb-partner.png";
import {getMailChimpEndPoint} from "../../utils";
import ReactGA from "react-ga"
import Modali, { useModali } from "../modal"
import XeroLogo from "../../images/partner/xero-logo-new.svg"
import MyobLogo from "../../images/partner/myob-logo.svg"
import QboLogo from "../../images/partner/qbo-logo-new.svg"

import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input/input'
import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'

const convertToSentenceCase = (text) => {
  if(text) {
    text = text.split("_").join(' ')
    return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  }
}

const inputCls =
    "form-input w-full py-2 px-3  text-brand focus:outline-none focus:shadow-outline placeholder-gray-28"
export default function Hero(props) {
  const [plan, setPlan] = useState([])
  const [videoModal1, toggleVideoModal1] = useModali();
  const [videoModal2, toggleVideoModal2] = useModali();
  const [videoModal3, toggleVideoModal3] = useModali();
  const [starterVideoModal1, toggleStarterVideoModal1] = useModali();
  const [starterVideoModal2, toggleStarterVideoModal2] = useModali();
  const [starterVideoModal3, toggleStarterVideoModal3] = useModali();
  const [starterVideoModal4, toggleStarterVideoModal4] = useModali();
  const [blbPartnerVideo, toggleBlbPartnerVideo] = useModali();
  const [mailChimpEndPoint, setMailChimpEndPoint] = useState("")
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")
  const [phone, setPhone] = useState("")
  const [promo, setPromo] = useState("")
  const [errorMsg, setErrorMsg] = useState("")
  const [isSpecialOfferCode, setSpecialOfferCode] = useState(false);
  const [firstnameCls, setfirstnameCls] = useState("")
  const [lastnameCls, setLastnameCls] = useState("")
  const [usernameCls, setUsernameCls] = useState("")
  const [passwordCls, setPasswordCls] = useState("")
  const [passwordConfirmCls, setPasswordConfirmCls] = useState("")
  const [phoneNumberCls, setPhoneNumberCls] = useState("")
  const [userAccepetanceCls, setUserAccepetanceCls] = useState(false)
  const [promoCls, setPromoCls] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [referralToken, setReferralToken] = useState("")
  const [lucapay, setLucapay] = useState(false)
  const [steps, setSteps] = useState(["Sign up", "LUCA Plus account set up", "Complete"])
  const urlState = queryString.parse(props.location.search)
  const { state = {} } = props.location;
  const planName = urlState.plan || (state && state.plan);
  const isTrial = state ? state.isTrial : true;
  const price = planName === "combined" ? 79 : planName === "get_paid" ? 49 : planName=== "pay_bills" ? 39 : 0;
  const isLucapayLite = urlState && urlState.lucapayLite;

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    if (typeof window !== "undefined") {
      setPromo(getAllUrlParams().promo_code)
      console.log(getAllUrlParams().email)
      setUsername(getAllUrlParams().email)
      if (urlState.ref_token) {
        setReferralToken(urlState.ref_token);
      } else if(urlState.refer) {
        setReferralToken(urlState.refer);
      }
    }
    setMailChimpEndPoint(getMailChimpEndPoint());

    if(state && state.promoCode) {
      setPromo(state.promoCode)
    }

    const decodeData = getAllUrlParams().d && JSON.parse(Buffer.from(getAllUrlParams().d, "base64").toString())
    if(decodeData){
      setFirstname(decodeData.firstName)
      setLastname(decodeData.lastName)
      setUsername(decodeData.email)
      setPromo(decodeData.promoCode)
    }

    if (planName) {
      if(planName === "solo"){
        setPlan(SOLO_PLAN);
      } else if(planName === "starter"){
        setPlan(STARTER_PLAN);
      } else if(planName === "standard") {
        setPlan(STANDARD_PLAN);
      } else if (planName === "combined") {
        setPlan(COMBINED_PLAN);
      } else if (planName === "pay_bills") {
        setPlan(PAY_BILLS_PLAN)
      } else if (planName === "get_paid") {
        setPlan(GET_PAID_PLAN)
      } else if(planName === "partner" || planName === "blb-partner") {
        setPlan(SOLO_PLAN)
      }
      // else if(planName === "blb-partner") {
      //   setPlan(BLB_PARTNER)
      // }
    } else {
      // navigate("/einvoicing-australia")
    }

    if(urlState && urlState.plan && (urlState.plan === "combined" || urlState.plan === "get_paid") || (state && state.myob)){
      if(urlState.page === "myobmarketplace"){
        ReactGA.event({
          category: `myobmarketplace_tryfree_tab`,
          action: `myobmarketplace_tryfree_tab`
        });
      }
    }

    if(state) {
      if(state.nsip){
        setPromo(generateNSIPPromoCode())
      }
      if(state.registrationDetails){
        setUsername(state.registrationDetails.username || state.registrationDetails.email)
        setFirstname(state.registrationDetails.firstname || state.registrationDetails.firstName)
        setLastname(state.registrationDetails.lastname || state.registrationDetails.lastName)
        setPromo(state.registrationDetails.promo || state.registrationDetails.promoCode)
      }
      if(planName !== "starter" && (state.isPromoCode || (state.registrationDetails && state.registrationDetails.isPromoCode))){
        // setPromo("30DaysFree")
      }
      if(state.lucapay) {
        setLucapay(true)
        setSteps([`Sign up`, "Account set up", `LUCA Pay ${isLucapayLite ? "Lite" : ""} Onboarding`])
      }
    }
    if(urlState?.fromHomePage) {
      setLucapay(true)
      setSteps([`Sign up`, "Account set up", `Credit Assessment Process`])
    } else if(!urlState?.fromHomePage && ((state && state?.lucapay) || urlState?.lucapay)) {
      setLucapay(true)
      setSteps([`Sign up`, "Account set up", `LUCA Pay ${isLucapayLite ? "Lite" : ""} Onboarding`])
    }

    return (() => {
      window.removeEventListener('beforeunload', handleUnload);
    })
  }, [])

  const handleUnload = () => {
    if(state && state.page){
      ReactGA.event({
        category: `${state.page}_Closewindow_insideapp_${planName}plan`,
        action: `${state.page}_Closewindow_insideapp_${planName}plan`
      });
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setfirstnameCls("")
    setLastnameCls("")
    setUsernameCls("")
    setPasswordCls("")
    setPasswordConfirmCls("")
    setPhoneNumberCls("")

    if (!firstname) {
      alert("Please enter  your First name")
      setfirstnameCls("border-red")
      return
    }
    if (!lastname) {
      alert("Please enter  your  Last name")
      setLastnameCls("border-red")
      return
    }

    if (!username) {
      alert("Please enter your Email")
      setUsernameCls("border-red")
      return
    }
    if (urlState?.fromHomePage && !phone) {
      alert("Please enter phone number")
      setPhoneNumberCls("border-red")
      return
    }

    if (!password) {
      alert("Please enter password")
      setPasswordCls("border-red")
      return
    }

    if (!passwordConfirm) {
      alert("Please enter confirm password")
      setPasswordConfirmCls("border-red")
      return
    }
    if (password !== passwordConfirm) {
      alert("Password confirmation doesn't match password")

      return
    }

    if (!userAccepetanceCls) {
      alert("Please accept terms of services")
      return
    }
    if (phone && !isValidPhoneNumber(phone)) {
      alert("Invalid phone number")
      setPhoneNumberCls("border-red")
      return
    }

    if(promo) {
      const response = await validatePromoCode(event.target.value);
      if(!response.data || !response.data.data || !response.data.data.success){
        alert("Please enter valid Promo Code")
        setPromoCls("border-red")
        return
      }
    }

    setSubmitting(true)
    // Axios.post(`${process.env.GATSBY_APP_URL}` + "/api/v1/user/register", {
    //   firstname,
    //   lastname,
    //   username,
    //   password,
    //   contactNumber: phone,
    //   promoCode: promo,
    //   planName: planName,
    //   country: "Australia",
    //   isTrial: isTrial,
    //   type: planName === "partner" ? "partner" : "",
    //   referrer: referralToken
    // })
    //   .then(function(response) {
    //     setSubmitting(false)
    //     if (response) {
    //       if (response.data.success) {
    //         ReactGA.event({
    //           category: `signup_insideapp_${planName}plan`,
    //           action: `signup_insideapp_${planName}plan`
    //         });
    //         window.location.href = `${response.data.redirectUrl}${state && state.lucapay ? "&lucapay=true" : ""}`
    //       } else {
    //         if(response.data.msg === "Failed to register user"){
    //           props.toggleErrorModal()
    //           setTimeout(() => {
    //             props.toggleErrorModal();
    //             window.location.href = `${process.env.GATSBY_APP_URL}`
    //           }, 5000);
    //         } else {
    //           setErrorMsg(response.data.msg);
    //         }
    //       }
    //     }
    //   })
    //   .catch(function(error) {
    //     setSubmitting(false)
    //     if(error.response && error.response.data){
    //       setErrorMsg(error.response.data);
    //     } else {
    //       setErrorMsg("Something went wrong!");
    //     }
    //   })
    let tag = null;
    if(urlState.page === "myobmarketplace"){
      tag = ["#myoblandingpage", "#myobmarketplace"]
    } else if(state){
      if(state.page === "Pricing page") {
          tag = ["#pricingpage"]
      } else if(state.page === "NSIP page"){
          tag = ["#nsip"]
      } else if(state.page === "Einvoicing Aus"){
          tag = ["eozlandingpage"]
      } else if(state.page === "Myob"){
          tag = ["#myoblandingpage"]
      } else if(state.page === "Partner Page") {
          tag = ["A&B"]
      }
    }
      Axios.post(mailChimpEndPoint, {
                email: username,
                audienceId: "9c2b2b3fd3",
                tags: tag,
                mergeFields: {
                    FNAME: firstname,
                    LNAME: lastname,
                    EMAIL: username,
                    PHONE: phone ?  "+" + getCountryCallingCode("AU") + formatPhoneNumber(phone).replace(/[\s()]/g, "") : "",
                    ACCEPTTERM: userAccepetanceCls ? "yes" : "no",
                },
            })
                .then(function(response) {
                    console.log(response)
                    let plan = planName?.split('_');
                    plan = plan?.[0] || '' + (plan?.[1] || '')
                    if (response) {
                        if (response.data.success) {
                            setErrorMsg("")
                          Axios.post(`${process.env.GATSBY_APP_URL}` + "/api/v1/user/register", {
                            firstname,
                            lastname,
                            username,
                            password,
                            contactNumber: phone,
                            promoCode: promo,
                            planName: plan,
                            country: "Australia",
                            isTrial: isTrial,
                            type: planName === "partner" || planName === "blb-partner" ? "partner" : "",
                            referrer: referralToken
                          })
                              .then(function(response) {
                                setSubmitting(false)
                                if (response) {
                                  if (response.data.success) {
                                    ReactGA.event({
                                      category: `signup_insideapp_${planName}plan`,
                                      action: `signup_insideapp_${planName}plan`
                                    });
                                    window.location.href = `${response.data.redirectUrl}${lucapay ? "&lucapay=true" : ""}`
                                  } else {
                                    if(response.data.msg === "Failed to register user"){
                                      props.toggleErrorModal()
                                      setTimeout(() => {
                                        props.toggleErrorModal();
                                        window.location.href = `${process.env.GATSBY_APP_URL}`
                                      }, 5000);
                                    } else {
                                      setErrorMsg(response.data.msg);
                                    }
                                  }
                                }
                              })
                              .catch(function(error) {
                                setSubmitting(false)
                                if(error.response && error.response.data){
                                  setErrorMsg(error.response.data);
                                } else {
                                  setErrorMsg("Something went wrong!");
                                }
                              })
                        } else {
                          setSubmitting(false)
                            if (response.data.message) {
                                console.log(response.data.message)
                                setErrorMsg(response.data.message.split(". Use")[0])
                            }
                        }
                    } else {
                      setSubmitting(false)
                    }
                })
                .catch(function(error) {
                    setSubmitting(false)
                    setErrorMsg("Something went wrong, Please Try Again!");
                })
  }

  const validatePromoCode = async () => {
    let response;
    try {
      response = await Axios.get(`${process.env.GATSBY_APP_URL}` + `/api/v1/lucapay/verifyPromoCode?promoCode=${promo}`);
    } catch (e) {
      return e.response
    }
    return response
  }


  return (
      <>
      <div className="flex subscription-container">
        <div className="flex text-white ">
          <div
              className="bg-gray-200 items-center justify-center left-section min-h-screen bg-brand-blue relative"
              style={{
                backgroundBlendMode: "multiply",
                backgroundColor: "#43527D",
                backgroundImage: `url(${signUpBgImg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
          >
            <div className="flex justify-center mt-10">
              {lucapay ? <a href="/" className="lucapay-logo ml-0 sm:mx-0 flex items-center">
                <img src={lucapayLogoImage} alt="logo" className={`pr-3 ${urlState?.fromHomePage ? 'home-page-logo' : ''}`}/>
                Powered by
                {
                  urlState?.fromHomePage ? <img src={logoImg} alt="luca+-logo" className="home-page-logo mx-2 mb-2"/>
                    : <div className="text-white whitespace-no-wrap ml-3 hidden sm:block text">
                       LUCA Plus
                    </div>
                }
              </a> : <img src={logoImg} alt="luca+-logo"/>}
            </div>
            {lucapay &&
            <h6 className={classNames("text-white lucapay-text mx-auto text-16 text-center mb-6")}>
              {`${urlState?.fromHomePage ? 'You are experiencing a fast and seamless credit assessment process by signing up for a LUCA Plus account, unlocking access to our efficient cash flow payment solutions' : `You\'re one step closer in applying for LUCA Pay${isLucapayLite ? " Lite" : ""}, first let’s create a LUCA Plus account for you to fully use the LUCA Pay ${isLucapayLite ? "Lite" : ""} service`}`}
            </h6>}
            {
              !lucapay &&
              <div className="">
                {/*{!lucapay &&*/}
                <h5 className="text-white font-bold leading-snug mt-20 text-center">
                  {planName === "blb-partner" ? "Benefits for Partners" : "Your selected plan"}
                </h5>
                {/*}*/}
                <div className="flex flex-col border-box mx-16 my-8">
                  <div className={classNames("px-5 py-4", {"flex justify-center items-center ": !lucapay})}>
                    <h6 className="text-white leading-7 font-medium capitalize">
                      {planName === "blb-partner" ? "We Make it Easier For Partners to" : `${planName === "partner" ? 'Partner Success Program' : convertToSentenceCase(planName) + " Plan"}`}
                    </h6>
                    {planName !== "blb-partner" &&
                    <div>
                      {price ?
                        <h5 className="leading-7 font-bold text-mint2 ml-1">
                          ${price}
                          <span className="text-base">
                          /mo
                          <span className="uppercase text-white text-xs font-medium ml-1">
                            AUD
                          </span>
                        </span>
                        </h5>
                        : urlState.page !== "myobmarketplace" &&
                        <h6 className="leading-7 tracking-widest font-bold text-mind ml-1">
                          FREE
                        </h6>}
                    </div>}
                  </div>
                  {
                    (planName === 'combined' || planName === 'get_paid' || planName === 'pay_bills') &&
                    <div className={`flex mb-4 justify-center items-center w-full bg-transparent px-2`}>
                      <div className={`border-bottom w-1/4`} />
                      <div className={`w-1/3 text-xs`}>
                        30 days free trial
                      </div>
                      <div className={`border-bottom w-1/4`} />
                    </div>
                  }
                  <ul className="px-8 py-3 items-center">
                    {plan.map(data =>
                      (data.icon === "checked" && (!lucapay || (lucapay && !data.trialText))) &&
                      <li className="flex mb-5">
                        <img src={checkImg} size={14} className="mr-3"/>
                        <span>{data.content}</span>
                      </li>
                    )}
                  </ul>
                </div>
                <div className='text-16 text-white font-medium mt-5 pb-4 hidden'>
                  {planName === "blb-partner" ? <span>Watch how simple it is to sign up for the Partner Program.</span> : <span>Watch how simple it is to use your <span className='capitalize'>{planName}</span> Plan</span>}
                  {planName === "blb-partner" ?
                    <div className={`flex justify-content-center w-full mt-5`}>
                      <div className='w-24  cursor-pointer' onClick={toggleBlbPartnerVideo}>
                        <img src={blbPartnerImg} />
                      </div>
                    </div>
                    : planName === 'combined' ?
                      <div className="flex justify-content-center mt-4">
                        <div className='w-24 cursor-pointer' onClick={toggleVideoModal1}>
                          <img src={signupUserImg} />
                        </div>
                        <div className='w-24 mx-3 cursor-pointer' onClick={toggleVideoModal2}>
                          <img src={signupClientImg} />
                        </div>
                      </div>
                      : <div className="flex flex-col justify-content-center mt-4">
                        <div className='flex justify-content-center'>
                          <div className='w-24 cursor-pointer' onClick={toggleStarterVideoModal1}>
                            <img src={ssignupUserImg} />
                          </div>
                          <div className='w-24 mx-3 cursor-pointer' onClick={toggleStarterVideoModal2}>
                            <img src={ssignupBusinessImg} />
                          </div>
                        </div>
                        <div className='flex justify-content-center mt-3'>
                          <div className="w-24 cursor-pointer" onClick={toggleStarterVideoModal3}>
                            <img src={ssignupPracticeImg} />
                          </div>
                          <div className="w-24 mx-3 cursor-pointer" onClick={toggleStarterVideoModal4}>
                            <img src={ssignupClientImg} />
                          </div>
                        </div>
                      </div>}
                </div>
                {lucapay &&
                <a className="link text-white underline text-15" href="https://meetings.hubspot.com/leo-huang">
                  Still have questions?
                </a>}
              </div>
            }
            </div>
        </div>
        <div className="flex flex-col my-10 w-full px-5 lg:px-10 right-content ">
          <div className={classNames("flex justify-between step", {"lucapay-step": lucapay})}>
            {steps.map((step, index) =>
                <div className="flex items-center step-container mx-3">
                  <div className={`step-icon ${index === 0 && 'active'}`}>
                    {index + 1}
                  </div>
                  <div className={`step-content capitalize ${index === 0 && 'active'} mx-3`} key={index}>
                    {step}
                  </div>
                </div>
            )}
          </div>
          <div className="z-20 inner items-center">
            <div className="flex flex-col sm:flex-row items-center justify-around">
              <form
                  className="register-form w-full sm:w-auto z-30"
                  onSubmit={handleSubmit}
              >
                <div className="card">
                  <div>
                    <h3 className="text-brand pt-10 font-bold">
                      {urlState?.fromHomePage ? 'Let\'s start by signing you up with a LUCA Plus account' :lucapay ? <span className="text-20">Let's start by signing you up with a LUCA Pay {isLucapayLite ? "Lite" : ""} account</span>
                          :  planName === "blb-partner" || planName === "partner" ? <span>Sign up for the Partner Success Program.</span> : <span>Sign up for the <span className="capitalize">{convertToSentenceCase(planName)}</span>{' '}Plan</span>}
                    </h3>
                    <div className="flex mt-3 justify-content-center items-center">
                      *Requires linking your {" "}<img src={XeroLogo} className="mx-2 w-10" />{" "}<img src={MyobLogo} className=" w-10 mr-2" />{" "} or{" "}<img src={QboLogo} className=" w-20 mx-2" />{" "} account
                    </div>
                  </div>
                  <div className="lhero-form mx-auto">
                    {errorMsg && (
                        <div className=" text-red-600 mb-4 text-left">{errorMsg}</div>
                    )}
                    <div className="flex mt-4">
                      <div className="relative w-1/2 mr-1">
                      <label className="block " htmlFor="firstName">
                        <input
                            className={`${inputCls} ${firstnameCls}`}
                            id="firstName"
                            type="text"
                            placeholder="First name"
                            value={firstname}
                            onChange={event => {
                              setFirstname(event.target.value)
                            }}
                        />
                        <div className="absolute required-icon">
                          <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.25586 3.28027L0.191406 2.66504L0.505859 1.63281L2.57031 2.39844L2.50879 0.046875H3.55469L3.48633 2.43262L5.5166 1.68066L5.83105 2.71973L3.73242 3.3418L5.08594 5.19434L4.23828 5.83691L2.9668 3.86816L1.73633 5.78906L0.881836 5.16699L2.25586 3.28027Z" fill="#F5222D"/>
                          </svg>
                        </div>
                      </label>
                      </div>
                      <div className="relative w-1/2 ml-1">
                        <label className="block " htmlFor="lastname">
                          <input
                              className={`${inputCls} ${lastnameCls}`}
                              id="lastname"
                              type="text"
                              placeholder="Last name"
                              value={lastname}
                              onChange={event => {
                                setLastname(event.target.value)
                              }}
                          />
                          <div className="absolute required-icon">
                            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2.25586 3.28027L0.191406 2.66504L0.505859 1.63281L2.57031 2.39844L2.50879 0.046875H3.55469L3.48633 2.43262L5.5166 1.68066L5.83105 2.71973L3.73242 3.3418L5.08594 5.19434L4.23828 5.83691L2.9668 3.86816L1.73633 5.78906L0.881836 5.16699L2.25586 3.28027Z" fill="#F5222D"/>
                            </svg>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="mt-4 relative">
                      <label className="block " htmlFor="email">
                        <input
                            className={`${inputCls} ${usernameCls}`}
                            id="email"
                            type="text"
                            placeholder="Email"
                            value={username || ""}
                            onChange={event => {
                              setUsername(event.target.value)
                            }}
                        />
                        <div className="absolute required-icon">
                          <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.25586 3.28027L0.191406 2.66504L0.505859 1.63281L2.57031 2.39844L2.50879 0.046875H3.55469L3.48633 2.43262L5.5166 1.68066L5.83105 2.71973L3.73242 3.3418L5.08594 5.19434L4.23828 5.83691L2.9668 3.86816L1.73633 5.78906L0.881836 5.16699L2.25586 3.28027Z" fill="#F5222D"/>
                          </svg>
                        </div>
                      </label>
                    </div>
                    <div className="mt-4 relative">
                      <label className="block " htmlFor="password">
                        <input
                            className={`${inputCls} ${passwordCls}`}
                            id="password"
                            type="password"
                            placeholder="Password"
                            onChange={event => {
                              setPassword(event.target.value)
                            }}
                        />
                        <div className="absolute required-icon">
                          <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.25586 3.28027L0.191406 2.66504L0.505859 1.63281L2.57031 2.39844L2.50879 0.046875H3.55469L3.48633 2.43262L5.5166 1.68066L5.83105 2.71973L3.73242 3.3418L5.08594 5.19434L4.23828 5.83691L2.9668 3.86816L1.73633 5.78906L0.881836 5.16699L2.25586 3.28027Z" fill="#F5222D"/>
                          </svg>
                        </div>
                      </label>
                    </div>
                    <div className="mt-4 relative">
                      <label className="block " htmlFor="confirmPassword">
                        <input
                            className={`${inputCls} ${passwordConfirmCls}`}
                            id="confirmPassword"
                            type="password"
                            placeholder="Confirm Password"
                            onChange={event => {
                              setPasswordConfirm(event.target.value)
                            }}
                        />
                        <div className="absolute required-icon">
                          <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.25586 3.28027L0.191406 2.66504L0.505859 1.63281L2.57031 2.39844L2.50879 0.046875H3.55469L3.48633 2.43262L5.5166 1.68066L5.83105 2.71973L3.73242 3.3418L5.08594 5.19434L4.23828 5.83691L2.9668 3.86816L1.73633 5.78906L0.881836 5.16699L2.25586 3.28027Z" fill="#F5222D"/>
                          </svg>
                        </div>
                      </label>
                    </div>
                    <div className="relative mt-4">
                      <label className="block " htmlFor="phone">
                        <PhoneInput
                          id="phone"
                          international
                          withCountryCallingCode
                          country="AU" // Set default country to Australia
                          value={phone}
                          onChange={(value) => setPhone(value)}
                          placeholder="Phone"
                          className={`${inputCls} ${phoneNumberCls}`}
                        />
                        {/*<input*/}
                        {/*    className={`${inputCls}`}*/}
                        {/*    id="phone"*/}
                        {/*    type="tel"*/}
                        {/*    placeholder="Phone"*/}
                        {/*    value={phone || ""}*/}
                        {/*    onChange={event => {*/}
                        {/*      setPhone(event.target.value)*/}
                        {/*    }}*/}
                        {/*/>*/}
                        {
                          urlState?.fromHomePage &&
                          <div className="absolute required-icon">
                            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2.25586 3.28027L0.191406 2.66504L0.505859 1.63281L2.57031 2.39844L2.50879 0.046875H3.55469L3.48633 2.43262L5.5166 1.68066L5.83105 2.71973L3.73242 3.3418L5.08594 5.19434L4.23828 5.83691L2.9668 3.86816L1.73633 5.78906L0.881836 5.16699L2.25586 3.28027Z" fill="#F5222D"/>
                            </svg>
                          </div>
                        }
                      </label>
                    </div>
                    <div className="mt-4">
                      <label className="block " htmlFor="promo">
                        <input
                            className={`${inputCls} ${promoCls}`}
                            id="promo"
                            type="text"
                            placeholder="Promo code"
                            value={promo || ""}
                            onChange={event => {
                              setPromo(event.target.value)
                            }}
                        />
                      </label>
                    </div>
                    <div className="mt-4">
                      <label className="block " htmlFor="location">
                        <select
                            className={`${inputCls}`}
                            id="location"
                            placeholder="Location"
                            disabled
                        >
                          <option>Australia</option>
                        </select>
                      </label>
                    </div>
                    <div
                        className="mt-3 w-full flex items-start footer-link sm:items-center justify-start"
                    >
                      <input
                          type="checkbox"
                          name="checkbox2"
                          checked={userAccepetanceCls}
                          onChange={() => setUserAccepetanceCls(!userAccepetanceCls)}
                          className="bg-white  border border-gray-27"
                      />
                      <span className="text-sm text-left label ml-2">
                      I accept Plus <a href="https://docs.lucaplus.com/terms-of-service" target="_blank" >Terms of Services</a> and <a href="https://docs.lucaplus.com/security-compliance-privacy" target="_blank">Privacy Policy</a>
                    </span>
                    </div>
                    <div className="mt-5 text-white">
                      <button
                          type="submit"
                          className={`${submitting ? "bg-gray-6 hover:bg-gray-6" : "bg-mind"} flex justify-center rounded w-full  text-base register-btn focus:outline-none`}
                          disabled={submitting}
                      >
                        {submitting ?
                          <div className="flex items-center">
                            <img
                              src={loadingImg}
                              className="loading mr-2"
                            />
                            loading...
                          </div>
                          : "Sign Up"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
        <Modali.Modal {...videoModal1}>
          <div className="hero-video">
            <iframe
              src="https://www.youtube.com/embed/kGajZpdUa6s?autoplay=1&enable_js=1"
              title="Add to contact"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              allow="autoplay"
            />
          </div>
        </Modali.Modal>
        <Modali.Modal {...videoModal2}>
          <div className="hero-video">
            <iframe
              src="https://www.youtube.com/embed/W837a-mj3NI?autoplay=1&enable_js=1"
              title="Send invoice"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              allow="autoplay"
            />
          </div>
        </Modali.Modal>
        <Modali.Modal {...videoModal3}>
          <div className="hero-video">
            <iframe
              src="https://www.youtube.com/embed/W837a-mj3NI?autoplay=1&enable_js=1"
              title="Sign up"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              allow="autoplay"
            />
          </div>
        </Modali.Modal>

        <Modali.Modal {...starterVideoModal1}>
          <div className="hero-video">
            <iframe
              src="https://www.youtube.com/embed/TsflMMUPW8c?autoplay=1&enable_js=1"
              title="Sign up"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              allow="autoplay"
            />
          </div>
        </Modali.Modal>
        <Modali.Modal {...starterVideoModal2}>
          <div className="hero-video">
            <iframe
              src="https://www.youtube.com/embed/cnUr52rPFm4?autoplay=1&enable_js=1"
              title="Sign up"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              allow="autoplay"
            />
          </div>
        </Modali.Modal>
        <Modali.Modal {...starterVideoModal3}>
          <div className="hero-video">
            <iframe
              src="https://www.youtube.com/embed/s7SjBsqaMF4?autoplay=1&enable_js=1"
              title="Sign up"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              allow="autoplay"
            />
          </div>
        </Modali.Modal>
        <Modali.Modal {...starterVideoModal4}>
          <div className="hero-video">
            <iframe
              src="https://www.youtube.com/embed/sS56nIA9Th0?autoplay=1&enable_js=1"
              title="Sign up"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              allow="autoplay"
            />
          </div>
        </Modali.Modal>
        <Modali.Modal {...blbPartnerVideo}>
          <div className="hero-video">
            <iframe
              src="https://www.youtube.com/embed/EcHO7q5Xu3Y?autoplay=1&enable_js=1"
              title="Sign up"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              allow="autoplay"
            />
          </div>
        </Modali.Modal>
      </>
  )
}
