import React from "react"
import comingSoonImg from "../images/new-pricing/coming-soon.png"

// const ADDITIONAL_FEATURE_TOOLTIP_TEXT = (<ul>
//   <li>Cash Flow Forecast</li>
//   <li>Revenue Forecast</li>
//   <li>Debtor/Creditor Reconciliation</li>
//   <li>Transactions Amendment Report</li>
// </ul>);
export const SOLO_PLAN = [
  {
    content: "LUCA Pay for getting paid and paying bills on time", icon: "checked",
    // tooltip: true,
    tooltip: false,
    // tooltipText:  <span>
    //                 If you work with a Bookkeeper or Accountant you can add them
    //                 to your LUCA Plus platform.
    //               </span>,
    // tooltipHeight: "99px",
    // tooltipWidth: "211px",
    iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Built-in Income Projection", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Send unlimited e-invoices", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Receive unlimited e-invoices as bills", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Track full invoices life cycle", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Unlimited historical data", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Earn and refer program", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Customer Payment Behavior Report", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Customer Invoice Risk Assessment", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  }
  ]
export const GET_PAID_PLAN = [
  {
    content: "Access to LUCA Pay* for getting paid on time", icon: "checked",
    // tooltip: true,
    tooltip: false,
    // tooltipText:  <span>
    //                 If you work with a Bookkeeper or Accountant you can add them
    //                 to your LUCA Plus platform.
    //               </span>,
    // tooltipHeight: "99px",
    // tooltipWidth: "211px",
    iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Send unlimited PEPPOL e-invoices (QBO Only)", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Unlimited Email & SMS Reminder for late payments", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Debtor Chase < 30 Phone Calls", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Debtor Chase < 5 Letter of Demands", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Customer Payment Behavior Report", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Track full invoices life cycle", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Customer Invoice Risk Assessment", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Built-in Late Payment Projection", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  }
  // {
  //   content: "Additional features", icon: "unchecked", tooltip: true,
  //   tooltipText: ADDITIONAL_FEATURE_TOOLTIP_TEXT,
  //   tooltipHeight: "127px",
  //   tooltipWidth: "204px",
  //   iconImg:'/assets/img/unchecked.svg'
  // }
];

export const PAY_BILLS_PLAN = [
  {
    content: "Access to LUCA Pay* for paying bills via instalments", icon: "checked", tooltip: false
  },
  {
    content: "CashWise that provides cash flow payment advice powered by AI & ML", icon: "checked", tooltip: false
  },
  {
    content: " Receive unlimited PEPPOL e-invoices (QBO Only)", icon: "checked", tooltip: false
  },
  {
    content: "Smart upcoming due dates & overdue bills reminder", icon: "checked", tooltip: false
  },
  {
    content: "Receipts and bills data capturing & automatic data entry", icon: "checked", tooltip: false
  },
  {
    content: "Export invoice data to Accounting Software", icon: "checked", tooltip: false
  },
  {
    content: "Upload bills via OCR", icon: "checked", tooltip: false, comingSoonImg: comingSoonImg
  },
  {
    content: "Pay bills in batch", icon: "checked", tooltip: false
  },
  {
    content: "Track full bills life cycle", icon: "checked", tooltip: false
  },
  // {
  //   content: "Additional features", icon: "unchecked", tooltip: true,
  //   tooltipText: ADDITIONAL_FEATURE_TOOLTIP_TEXT,
  //   tooltipHeight: "127px",
  //   tooltipWidth: "204px"
  // }
];

export const COMBINED_PLAN = [
  {
    content: "Access to LUCA Pay* for getting paid on time", icon: "checked",
    tooltip: false,
    iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Send/Receive unlimited PEPPOL e-invoices (QBO Only)", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Unlimited Email & SMS Reminder for late payments", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Debtor Chase < 30 Phone Calls", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Debtor Chase < 5 Letter of Demands", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Customer Payment Behavior Report", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Track full invoices life cycle", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Customer Invoice Risk Assessment", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Built-in Late Payment Projection", icon: "checked", tooltip: false, iconImg:'/assets/img/checked.svg'
  },
  {
    content: "Access to LUCA Pay* for paying bills via instalments", icon: "checked", tooltip: false
  },
  {
    content: "CashWise that provides cash flow payment advice powered by AI &ML", icon: "checked", tooltip: false
  },
  {
    content: "Smart upcoming due dates & overdue bills Reminder", icon: "checked", tooltip: false
  },
  {
    content: "Receipts and bills data capturing & automatic data entry", icon: "checked", tooltip: false
  },
  {
    content: "Export invoice data to Accounting Software", icon: "checked", tooltip: false
  },
  {
    content: "Pay bills in batch", icon: "checked", tooltip: false
  },
  {
    content: "Track full bills life cycle", icon: "checked", tooltip: false
  }
];


export const BLB_PARTNER = [
  {
    content: "Invite clients to sign up for LUCA Pay", icon: "checked", tooltip: false
  },
  {
    content: "Track the status of the signing-up process after the invite", icon: "checked", tooltip: false
  },
  {
    content: "See how much commission you earned in total", icon: "checked", tooltip: false,
  }
]

export const STARTER_PLAN = [
  {
    content: "LUCA Pay for getting paid and paying bills on time", icon: "checked", tooltip: false
  },
  {
    content: "Built-in Income Projection", icon: "checked", tooltip: false
  },
  {
    content: "Send unlimited e-invoices", icon: "checked", tooltip: false
  },
  {
    content: "Receive 10 e-invoices as bills", icon: "checked", tooltip: false
  },
  {
    content: "Track full invoices life cycle", icon: "checked", tooltip: false
  },
  {
    content: "Unlimited historical data", icon: "checked", tooltip: false
  },
  {
    content: "Earn and refer program", icon: "checked", tooltip: false
  },
  {
    content: "Customer Payment Behavior Report", icon: "unchecked", tooltip: false,
  },
  {
    content: "Customer Invoice Risk Assessment", icon: "unchecked", tooltip: false,
  },
  {
    content: "Transaction Amendment Report", icon: "unchecked", tooltip: false,
  }
  // {
  //   content: "Additional features", icon: "unchecked", tooltip: true,
  //   tooltipText: ADDITIONAL_FEATURE_TOOLTIP_TEXT,
  //   tooltipHeight: "127px",
  //   tooltipWidth: "204px"
  // }
];

export const STANDARD_PLAN = [
  {
    content: "LUCA Pay for getting paid and paying bills on time", icon: "checked", tooltip: false,
    // tooltipText: <span>
    //                 Enable collaboration amongst your
    //                 team and also with your Accountant
    //                 or Bookkeeper.
    //               </span>,
    // tooltipHeight: "99px",
    // tooltipWidth: "224px"
  },
  {
    content: "Built-in Income Projection", icon: "checked", tooltip: false
  },
  {
    content: "Send unlimited e-invoices", icon: "checked", tooltip: false
  },
  {
    content: "Receive unlimited e-invoices as bills", icon: "checked", tooltip: false
  },
  {
    content: "Track full invoices life cycle", icon: "checked", tooltip: false
  },
  {
    content: "Unlimited historical data", icon: "checked", tooltip: false
  },
  {
    content: "Earn and refer program", icon: "checked", tooltip: false
  },
  {
    content: "Customer Payment Behavior Report", icon: "checked", tooltip: false
  },
  {
    content: "Customer Invoice Risk Assessment", icon: "checked", tooltip: false
  },
  {
    content: "Transaction Amendment Report", icon: "checked", tooltip: false
  },
  // {
  //   content: "Additional features", icon: "checked", tooltip: true,
  //   tooltipText: ADDITIONAL_FEATURE_TOOLTIP_TEXT,
  //   tooltipHeight: "127px",
  //   tooltipWidth: "204px"
  // }
];
