import React from "react"
import { navigate } from "gatsby"

import SEO from "../components/seo"
import SubscriptionSignup from "../components/subscription-signup"
import Modali, { useModali } from "../components/modal"
import highFiveImg from "../images/sign-up/high-five.svg"

import "../scss/style.scss"
export default function SubscriptionSignupPage(props) {
  const [errorModal, toggleErrorModal] = useModali({
    onHide: () => navigate(`${process.env.GATSBY_APP_URL}`),
  })
  return (
      <div className="h-full font-robo subscription-sign-up">
        <SEO title="Sign up for Your LUCA+ E-invoicing Plan Here" description="Sign up with LUCA+ to automatically populate invoices directly into your accounting platform." />
        <Modali.Modal {...errorModal} className=" error-modal" >
          <div className="pt-8 pop-up">
            <div className="pop-up-inner mx-auto ">
              <img src={highFiveImg} alt="" className="inbox-image mx-auto" />
              <div className="leading-relaxed mt-8">
                This email address is already registered as a LUCA Plus account. You will now be redirected to the login page.
              </div>
              <div className="mt-8 text-gray-8  text-sm">Use the forgot password link if you do not have your password.</div>
            </div>
          </div>
        </Modali.Modal>
        <SubscriptionSignup {...props} toggleErrorModal={toggleErrorModal} />
      </div>
  )
}
